.solid,
.outline {
  padding: 0.75rem 1.5rem;

  font-weight: 500;
  font-size: 0.875rem;

  @media screen and (min-width: 1024px) {
    font-size: 1rem;
    padding: 0.75rem 2rem;
  }
}

.solid {
  background-color: var(--clr-white);
  color: var(--clr-accent-text);
  transition: background-color 0.3s ease;
  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.outline {
  border: 2px solid var(--clr-accent-text);
  color: var(--clr-white);
  transition: all 0.3s ease;

  &:hover {
    background-color: rgba(255, 255, 255, 0.08);
    color: var(--clr-action);
  }
}

.medium {
  padding: 0.75rem 1.5rem;
  font-size: 0.875rem;
  border-radius: var(--br-24);

  @media screen and (min-width: 1024px) {
    font-size: 1rem;
    padding: 0.75rem 2rem;
  }
}

.big {
  padding: 0.875rem 3rem;
  font-size: 1.125rem;
  border-radius: var(--br-24);

  @media screen and (min-width: 1024px) {
    padding: 1rem 4rem;
    font-size: 1.25rem;
    border-radius: var(--br-32);
  }
}
