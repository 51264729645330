.navListMobile {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  align-items: flex-start;

  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.navListDesktop {
  display: flex;
  align-items: center;
  gap: 3.75rem;
  height: 100%;
  display: none;

  @media screen and (min-width: 1024px) {
    display: flex;
  }

  .navLink {
    display: block;
    font-size: 1.125rem;
    transition: all 0.3s ease;
    padding: 1rem 0;

    &:hover {
      color: var(--clr-action);
    }
  }
}

.navItem {
  display: flex;
  align-items: center;
  height: 100%;
}
