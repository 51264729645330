.header {
  position: absolute;
  top: 1.5rem;
  left: 0;
  width: 100dvw;
  z-index: 50;

  @media screen and (min-width: 1024px) {
    top: 2.5rem;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 1.5rem;
  border-radius: var(--br-32);
  background: var(--clr-header-background);

  backdrop-filter: blur(180px);

  @media screen and (min-width: 1024px) {
    padding: 0.75rem 1.5rem;
    height: 76px;
  }
}

.logoLink {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;

  &:hover {
    color: var(--clr-action);
  }
}

.logo {
  width: 33px;
  height: 36px;
  fill: var(--clr-white);
}

.logoText {
  font-size: 1.125rem;

  // @media screen and (min-width: 1024px) {
  //   font-size: 1.25rem;
  // }
}

.openApp {
  display: none;

  @media screen and (min-width: 1024px) {
    height: 44px;
    align-self: center;
    display: block;
  }
}
