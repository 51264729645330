.burger {
  display: block;
  position: absolute;
  top: 1px;
  right: 1rem;
  padding: 0.5rem 1.5rem;

  @media screen and (min-width: 1024px) {
    display: none;
  }
  svg {
    path {
      fill: var(--clr-white);
    }
  }
}

.headerMenu {
  display: flex;
  justify-content: space-between;

  font-size: 1.5rem;
  font-weight: 500;

  margin-bottom: 3.75rem;
}

.mobileMenu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100dvw;
  height: 100dvh;
  padding: 1.5rem;
  background: var(--clr-primary-background);
  z-index: 100;
  margin-top: -1.5rem;
  padding-top: 1.5rem;
  overflow: hidden;

  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.openApp {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  width: 200px;
  text-align: center;
}
